.grid-completar-registro{
    border: 1px solid #ccc;
    gap:2em !important;
}

.row-completar-registro{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.item-1-registro{
    box-shadow:0 0 10px 0 rgba(0,0,0,.2) !important;
    text-align: left !important;
    padding: 20px !important;
}
.espaciador-amarillo {
    margin-top: 1rem !important;
    background-color: #ffb200 !important;
    width: 5rem !important;
    height: 5px !important;
    border-radius: 10px !important;
}

.espaciador-amarillo-largo {
    margin-top: 1rem !important;
    background-color: #006D8E !important;
    width: 100% !important;
    height: 5px !important;
    border-radius: 10px !important;
    margin-bottom: 1rem;
}
.espaciador-amarillo-2{
    margin-top: 1rem !important;
    background-color: #ffb200 !important;
    width: 5rem !important;
    height: 5px !important;
    border-radius: 10px !important;
}

.item-2-registro{
    box-shadow:0 0 10px 0 rgba(0,0,0,.2) !important;
    text-align: left !important;
    padding: 20px !important;
}
.alerta{
    text-align: left!important;
}
.radio-group-completar-registro{
    margin: 10px 0px 10px 0px !important;
}

.input-completar-registro{
    
      background-color: #EDEDED !important;
      border-color: #EDEDED !important;
      border-radius: 3px !important;
      font-weight: 600 !important;
}


.circular-progress  {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    color: white !important;
}