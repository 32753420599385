.appbar{
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    z-index: 1 !important;
    backdrop-filter: blur(12px);
    opacity: 1 !important;

}
.logout-icon-button-appbar{
    color: #FFB200;
    font-size: 20px;
}

@media screen and (max-width: 600px) {
    .appbar{
        justify-content: center;
    }

    .css-hyum1k-MuiToolbar-root{
        justify-content: space-between !important;
    }
}