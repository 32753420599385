.icon-list-dashboard {
    color: white !important;
}
.text-list-dashboard {
    color: white !important;
}
.menu-dashboard {
    background-color: #031B4E !important;
}
.text-list-dashboard:hover {
    color:white !important;
    background-color: #F9AF02 !important;

}
.divider-menu-dashboard {
    background-color: white !important;
    height: 34px !important;
    color: aliceblue !important;
}
.options-menu-dashboard {
    align-items: flex-start !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper{
    background-color: #031B4E !important;
}