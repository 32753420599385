
.css-te3n8d-MuiPaper-root{
    text-align: left !important;
    padding: 0px !important;
}

.css-1j916qe-MuiPaper-root-MuiAlert-root{
    align-items: baseline !important;
}
.btn-complete-form{
    align-items: normal !important;

}