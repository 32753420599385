.box-shadow {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

.css-19kzrtu {
    padding: 0px !important;
}

.titulo h1 {
    font-size: 2.5rem;
    font-weight: 900;
    color: #2d3338;
    text-align: left;
}

.titulo-2 h1 {
    font-size: 2rem !important;
    font-weight: 900 !important;
    color: #2d3338 !important;
    text-align: center !important;
}

.subtitulo p {
    font-size: 1rem;
    font-weight: 400;
    color: #2d3338;
    text-align: left;
}

.btn-home-saber-mas {
    display: flex !important;
    align-items: left;
    padding: 10px !important;
}

.grid-box {
    display: grid;
    align-items: center !important;
}

.btn-contacten {
    display: flex !important;
    align-items: left;
    padding: 10px !important;
    background-color: #2d3338;
    color: #006d8e !important;
    border-radius: 5px;
    border-color: #006d8e !important;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    transition: 0.3s;
}

.btn-contratar {
    display: flex !important;
    align-items: left;
    padding: 10px !important;
    background-color: #006d8e !important;
    color: #fff;
    border-radius: 5px;
    border: none;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    transition: 0.3s;
}

.btn-contratar:hover {
    background-color: #ffb200 !important;
    color: black;
}
.css-83ijpv-MuiTypography-root {
    font-size: 0.9rem !important;
    font-weight: 400 !important;
    color: #202830;
    font-family: 'Roboto', sans-serif;
}

.titulo-3 h1 {
    font-size: 1.5rem !important;
    font-weight: 900 !important;
    color: #2d3338 !important;
    text-align: left !important;
}

.titulo-4 h1 {
    font-size: 1.5rem !important;
    font-weight: 900 !important;
    color: #2d3338 !important;
    text-align: center !important;
}


.subtitulo-2 {
    font-size: 1rem !important;
    font-weight: 400 !important;
    color: #2d3338 !important;
    text-align: center !important;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.container-2 {
    background-color: #F6F9FC !important;
    display: flex none !important;
}
.grid-2 {
    background-color: #F9FBFE !important;
}
.MuiDivider-root {

    height: 5px !important;
}
.list-info {
    text-align: left !important;
    font-size: 1rem !important;
    padding: 10px 25px 25px 20px !important;
    color: black;
    font-weight: 400;
}
.list-info li {
    list-style: none !important;
    margin-bottom: 0.5rem !important;
    margin-top: 20px;
}
.btn-llamar{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    background-color: #ffb200 !important;
    color: black !important;
}
.btn-contratar-2{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    border-color: #006d8e !important;
    color: #006d8e !important;

}
.icono-check-2 {
    color: #ffb200 !important;
    font-size: 1.5rem !important;
    margin-right: 0.5rem !important;
}
.espaciador-azul {
    margin-top: 2rem !important;
    background-color: #006d8e !important;
    width: 10rem !important;
    height: 5px !important;
    margin: auto !important;
    margin-bottom: 50px !important;
    border-radius: 10px !important;
}

.espaciador-azul-2 {
    margin-top: 2rem !important;
    background-color: #ffb200 !important;
    width: 10rem !important;
    height: 5px !important;
    margin: left !important;
    margin-bottom: 50px !important;
    border-radius: 10px !important;

}

.espaciador-azul-3 {
    margin-top: 2rem !important;
    background-color: #ffb200 !important;
    width: 10rem !important;
    height: 5px !important;
    margin: left !important;
    margin-bottom: 50px !important;
    border-radius: 10px !important;
}


.espaciador-azul-4 {
    margin-top: 1rem !important;
    background-color: #ffb200 !important;
    width: 5rem !important;
    margin: auto;
    height: 5px !important;
    border-radius: 10px !important;
}


.icons-services-1 {
    font-size: 2rem !important;
    color: white !important;
}



.css-2s90m6-MuiAvatar-root{
    background-color: #ffb200 !important;
}
.list-2 li{
    align-items: start !important;
margin: 0px !important;

}

.text-list {
    font-size: 1rem !important;
    font-weight: 400 !important;
    color: #2d3338 !important;
    text-align: left !important;
    margin-top: 0rem !important;
    margin-bottom: 0.5rem !important;
}
.box-btn-info{
    display: flex !important;
    justify-content: center !important;
}