.franja-up {
    width: 100%;
    height: 5px;
    background: #ffb200;
    position: absolute;
    top: 0;
    z-index: 9999;
}

.franja-up .rojo {
    width: 50%;
    height: 5px;
    background: #006d8e;
}

.nav-botones {
    gap: 20px;
}

.btn:hover {
    background: white !important;
    color: black !important;
}

.btn-2:hover {
    background: white !important;
    color: black !important;
}

.btn {
    background: #006d8e !important;
    color: #fff !important;
    font-weight: 600 !important;
}

.btn-2 {
    background: #ffb200 !important;
    color: white !important;
    font-weight: 600 !important;
}

.navbar a {
    color: white !important;
    font-weight: 300;
}

.logo-valinkpay {
    font-size: 1.5rem !important;

}