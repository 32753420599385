.tabs-info {
    text-align: left !important;
    font-size: 1.125rem !important;
    color: #2d3338 !important;
    margin-top: 80px;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #006d8e !important;
}
.tabs-info li{
    margin-bottom: 50px;
    list-style: none !important;
}
.icono-check-1{
    color: #006d8e !important;
    font-size: 1.5rem !important;
    margin-right: 0.5rem !important;
    ;
}